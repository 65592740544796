@import "../../scss/variables/colors";

.card-view-small {
  width: 52px;
  min-width: 52px;
}

.card-view-medium {
  width: 90px;
  min-width: 90px;
}

.card-view-smallest {
  width: 42px;
  min-width: 42px;
  border-radius: 3px;
}

.card-view-large,
.card-view-small,
.card-view-smallest,
.card-view-medium {
  position: relative;
  overflow: hidden;

  .card-image {
    width: 100%;
  }

  .mini-ips {
    position: absolute;
    width: 20%;
    height: 15%;
    top: 10%;
    left: 5%;
  }
}
