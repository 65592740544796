@import "../../../../../common/ui/src/lib/scss/variables/colors";

.ni-stage-details {
  :global {
    .ant-descriptions-row {
      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        padding: 12px !important;
      }
    }
  }
}
