@import "../../../../../common/ui/src/lib/scss/variables/colors";

.ni-table {
  background-color: $neutral-white;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  tr:nth-child(2n + 1) td {
    background-color: $main-black-14;
  }

  :global {
    .ant-dropdown-menu-title-content span {
      line-height: 20px;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
      )::before {
      display: none;
    }

    .ant-typography-copy {
      margin-top: 2px;
      
      .anticon {
        color: #8a8a8d;
      }
    }
  }

  .link-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}
