@import "../../../../../../../common/ui/src/lib/scss/variables/colors";

.ni-collapse {
  border: 1px solid rgba(5, 5, 5, 0.06) !important;

  :global {
    .ant-table-cell {
      border-radius: 0 !important;
    }

    .ant-collapse-content {
      overflow: hidden !important;
      border-top-color: rgba(5, 5, 5, 0.06) !important
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
    
    .ant-table-container {
      border: 0 !important;
      border-radius: 0 !important;
    }
  }
}