@import "../../scss/variables/colors";

.fc-header-filters {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fc-filter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  flex-wrap: wrap;

  .fc-filter-select {
    font-size: 13px;
  }

  .fc-filter-tab {
    margin-right: 12px;
  }
}

.fc-filter-search {
  width: 300px;
  height: 40px;

  svg {
    color: rgb(202, 202, 203);
  }

  ::placeholder {
    color: rgb(116, 116, 119);
  }
}

.fc-filter-button {
  font-size: 13px !important;
  color: #000 !important;
  border-color: rgb(199, 199, 200) !important;
  width: 100px !important;
  height: fit-content !important;
  border-radius: 5px;

  &.fc-highlighted {
    background-color: rgb(248, 248, 248);
    font-weight: 500;
  }
}

:global {
  .ant-select-selection-placeholder {
    color: rgb(116, 116, 119) !important;
  }

  .ant-picker.ant-picker-range {
    height: 40px !important
  }
}
