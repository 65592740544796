@import "../../../../../../../common/ui/src/lib/scss/variables/colors";

.ni {
  &-drag-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #1677ff;
  }

  &-drag-text {
    color: rgba(0,0,0,0.88);
    font-size: 16px;
    margin-bottom: 4px !important;
  }
}